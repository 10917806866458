.about-h1 {
  text-align: center;
  color: var(--h1-text-color);
  font-size: 5rem;
  font-weight: 400;
}

.about-text{
  display: flex;
  justify-content: center;
}

.about-p {
    padding: 1.5rem;
    margin: 0 2rem;
    color: antiquewhite;
    position: relative;
    max-width: 1000px;
}

.about-p::before {
  padding: 3px;
  content: '';
  position: absolute;
  top: 1.5rem;
  bottom: 2rem; /* border length */
  left: 0;
  width: 2px; 
  background-color: rgb(188, 120, 51); 
}

.about-page-body {
  text-align: justify;
    background-size: cover;
    z-index: 0;
    position: relative;
    height: fit-content;
}


.about-hero {
    background-color: #3498db;
    z-index: -1;
    position: relative;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60% 30%;
    /* background-image: url('../images/Photo\ by\ Diaspora\ Arts\ 5.jpg'); */
    clip-path: polygon(0 0, 100% 0, 100% 90%, 80% 100%, 0 80%);
  }

  