.loader-body {
  font-family: 'Ysabeau', sans-serif;
  font-weight: 200;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: linear-gradient(to bottom right, rgb(0, 40, 133), rgb(70, 20, 100)); /* Initial gradient background */
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-out; /* Adjust transition duration as needed */
  overflow: hidden; /* Ensures gradient animation stays within viewport */
  animation: undulateGradient 5s linear infinite alternate-reverse; /* Animation for undulating gradient */
}

.loader-body h1 {
  font-size: calc(3rem + 5vw);
  font-weight: 200;
  text-align: center;
}

@keyframes undulateGradient {
  0% {
    background-position: 0% 0%;
    background-color: rgb(0, 40, 133);
  }
  50% {
    background-position: 100% 100%;
    background-color: rgb(70, 20, 100);
  }
  100% {
    background-position: 0% 0%;
    background-color: rgb(0, 67, 133);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: antiquewhite;
}

.loader h1 {
  padding: 2rem;
}
.spinner {
  position: relative;
  display: inline-block;
}

.logo {
  width: 300px; /* Adjust size as needed */
  height: auto;
  filter: invert(90%);
}

.spinner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px; /* Adjust size as needed */
  height: 320px; /* Adjust size as needed */
  border: 5px solid #161d86b3; /* Light grey border */
  border-top: 3px solid #3498db; /* Blue border for spinning effect */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animation for spinning */
}
.fade-out {
  opacity: 0;
}

.hidden {
  visibility: hidden; /* Hide the loader, but keep the space it occupies */
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}