.calendar-hero { 
background-color: #000000;
z-index: -1;
position: relative;
height: 90vh;
background-repeat: no-repeat;
background-size: 60vw;
background-position: 60% 35%;
clip-path: polygon(0 0, 100% 0, 100% 90%, 80% 100%, 0 80%);

}

.calendar-page-body {
    background: 
    linear-gradient(rgba(0, 46, 121, 0.883) 50%, rgba(20, 0, 121, 0.673)), 
    url('../images/allison-saeng-EnG_17GAMc4[optimized].jpg'); 
    background-size: cover;
    z-index: 1;
    position: relative;
    height: fit-content;
}

.calendar-h1 {
    text-align: center;
  color: var(--h1-text-color);
  font-size: 5rem;
  font-weight: 400;
}

.bandsintown-widget {
    width: 100%;
}


@media (max-width: 1140px) {
    .calendar-hero{
        background-size: cover;
    }
    
 
    }