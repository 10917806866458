.education-page-body {
    background-color: rgb(14, 72, 123);
    text-align: justify;
}

.education-hero {
    background-color: #3498db;
    position: relative;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    background-position: 10% bottom;
    /* background-image: url('../images/Photo\ by\ Fadi\ Kheir\ 4.jpg'); */
    clip-path: polygon(0 0, 100% 0, 100% 90%, 80% 100%, 0 80%);
}

.education-text-line-border{
    border: 4px solid rgb(188, 120, 51);
    margin: 10px;
}

.education-text {
    margin: 0 50px;
    display: flex;
    justify-content: center;
}

.education-h1 {
    text-align: center;
    color: var(--h1-text-color);
    font-size: 5rem;
    font-weight: 400;
}

.education-h2 {
    text-align: center;
    color: var(--h1-text-color);
    font-size: 4rem;
    font-weight: 400;
}

.education-h3 {
    text-align: center;
    color: antiquewhite;
    font-size: 3rem;
    font-weight: 400;
}



.education-p{
    padding: 0.5rem;
    /* margin: 0 1rem; */
    color: antiquewhite;
    position: relative;
    max-width: 1000px;
}

.education-image-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
}

  .education-fading-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-color: rgb(14, 72, 123);
}

.education-youtube-container{
    background-color: rgb(14, 87, 123);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 100px 20px;
    clip-path: polygon(0% 0%,
                  100% 10%,
                   100% 100%,
                    0% 90%);
}

.education-youtube-container iframe {
    max-width: 90vw;
}
