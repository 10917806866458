.contact-body{
    background: 
    linear-gradient(rgba(0, 46, 121, 0.883) 50%, rgba(20, 0, 121, 0.673)), 
    url('../images/allison-saeng-EnG_17GAMc4[optimized].jpg'); 
    background-size: cover;
    z-index: 1;
    position: relative;
    height: fit-content;
}

.contact-body h1 {
  text-align: center;
  color: var(--h1-text-color);
  font-size: 5rem;
  font-weight: 400;
}

.contact-hero{
    background-color: #3498db;
    z-index: -1;
    position: relative;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 10%; 
    /* background-image: url('../images/Farah\ Siraj\ -\ Photo\ by\ Yasmina\ Barbero\ 4\ .jpg'); */
    clip-path: polygon(0 0, 100% 0, 100% 90%, 80% 100%, 0 80%);
  }
  
  .form-container{
    display: flex;
    justify-content: center;
  }
 