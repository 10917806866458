.media-page-body {
    background-color: rgb(14, 72, 123);
}

.media-h1 {
text-align: center;
  color: var(--h1-text-color);
  font-size: 5rem;
  font-weight: 400;
}


.media-hero {
    background-color: #2b2c2c;
    position: relative;
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% calc(3vw);
    /* background-image: url('../images/Photo\ by\ Dario\ Munoz\ Leva\ .jpg'); */
    clip-path: polygon(0 0, 100% 0, 100% 90%, 80% 100%, 0 80%);
}

.media-page-album-container {
    background-color: rgb(13, 87, 151);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 80px 20px;
    clip-path: polygon(0% 0%,
                  100% 10%,
                   100% 100%,
                    0% 90%);
}


/* see if you can combine this with the class above */
.media-youtube-container{
    background-color: rgb(14, 72, 123);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    padding: 100px 20px;
}

.media-youtube-container iframe{
   max-width: 90vw;
   
}

.spacer{
    padding-bottom: 40px;
    border-bottom: 2px solid grey;
}
