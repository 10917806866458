:root {
  --primary-tranparent: rgba(67, 120, 235, 0.666);
  --primary-solid: rgb(67, 120, 235);
  --secondary-transparent: rgba(71, 102, 168, 0.881);
  --secondary-solid: rgb(71, 102, 168);
  --background-color: rgb(211, 212, 193);
  --text-color: rgba(240, 240, 240, 1);
  --h1-text-color: rgb(22, 96, 255);
}


*{
  font-family: 'Nunito Sans', sans-serif;
  padding:0;
  margin:0;
  box-sizing: border-box;
}

body {
  font-family: 'Ysabeau', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  
}

h2 {
  font-weight: 400;
  color: var(--h1-text-color);
  font-size: 3rem;
}

.hero{
  z-index: -1;
  position: relative;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  background-attachment: fixed;
}

.hero-text{
  position: absolute;
  font-weight: 200;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to top, rgba(67, 120, 235, 0.66) 50%, rgba(67, 120, 235, 0));
}

.title {
  font-size: calc(6rem + 5vw);
  color: var(--text-color);
  text-align: center;
}

.subtitle {
  color:var(--text-color);
  font-size: 3rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.landing-page-section {
  font-size: calc(1em + 0.5vw);
  color: rgb(217, 217, 217);
  background-color: black;
  height: fit-content;
  min-height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-attachment: fixed;
}

.landing-page-section h1{
  text-align: center;
  color: var(--h1-text-color);
  font-size: 5rem;
  font-weight: 800;
}

/* landing page about section */
.about-container {
    display: flex;
    /* border: 3px solid rgb(255, 170, 0); */
    max-width: 1000px;
    border-radius: 10px;
    width: 100%;
    padding: 1rem;
}

.about-image {
  min-width: 100px;
  width: calc(20vw + 1rem); 
}

.about-container .vertical-line {
  background-color: var(--primary-solid); /* Line color */
  height: inherit; 
  padding: 1.5px;
  margin: 10px;
}

.right-container{
  text-align: justify;
}

/* landing page selected review section */

.review-landing-page-section {
  font-size: calc(1em + 0.5vw);
  color: rgb(217, 217, 217);
  background-color: black;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-attachment: fixed;
}

.selected-review-container {
  height: 70vh;
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 90%;
}
.video-landing-page-section {
  font-size: calc(1em + 0.5vw);
  color: rgb(217, 217, 217);
  background-color: black;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* keep element from resizing when animations come from outside the element */
  overflow: hidden;
}


.video-container video{
  width: 100%;
}

.selected-review {
  color: var(--text-color);
  font-weight: 300;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 5%, rgba(255, 255, 255, 0));
  padding: 20px;
  max-width: 1000px;
  font-size: 2.5rem;
}

.audio-player-landing-page-section {
background: 
  linear-gradient(rgba(0, 46, 121, 0.709) 50%, rgba(20, 0, 121, 0.535)), 
  url('./images/allison-saeng-EnG_17GAMc4[optimized].jpg'); 
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
padding: 80px 0;
clip-path: polygon(0% 0%,
                  100% 10%,
                   100% 100%,
                    0% 90%);
}

.audio-players-container {

gap: 16px;
}

.selected-review-container-2 {
  font-weight: 300;
  font-size: 2.5rem;
  height: 60vh;
  width: 100%;
  /* background-image: url('./images/Farah\ Siraj\ -\ Photo\ by\ Yasmina\ Barbero\ 5.jpg'); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.contact-landing-page-section {
  background: 
  linear-gradient(rgba(0, 46, 121, 0.709) 50%, rgba(20, 0, 121, 0.535)), 
  url('./images/allison-saeng-EnG_17GAMc4[optimized].jpg'); 
  background-size: cover; 
  background-position: center; 
  padding: 10px 0px;
  height: fit-content;
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-attachment: fixed;
  /* keep element from resizing when animations come from outside the element */
  overflow: hidden;
}

.contact-landing-page-section h1{
  text-align: center;
  color: rgb(0, 186, 211);
  font-size: 5rem;
  font-weight: 800;
}

.footer {
  display: block;
  background-color: rgb(60, 60, 60);
  padding: 2rem;
  font-size: 1rem;
  color: antiquewhite;
  text-align: center;
  /* margin-top: 40px; */
  border-top: 2px solid grey;
  
}

.website-by {
  margin-top: 1rem;
  text-align: end;
  
}

.website-by a{
  text-decoration: none;
  color: antiquewhite;
}

.social-icons-footer {
  display: flex ;
  justify-content: center;
  gap: 20px;
}

/* media queries */
@media (max-width:990px){
  .about-container{
    display: block;
  } 

  .about-image {
   width: 90%;
   margin-left: 5%;
  }

  .audio-players-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    }
}