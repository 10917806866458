.nav-link {
    text-decoration: none;
    color: rgb(218, 29, 29);
    padding: 10px;
    font-size: 1.5rem;
  }
  
  .nav-link.active {
    font-weight: bold;
    color: rgb(0, 168, 197);
  }
  
  .nav-link.inactive {
    color: rgb(14, 163, 222);
    font-weight: 400;
  }

header{
    background-color: #4e8eb1;
}
  /* Navbar styling */

.navbar {
    position: fixed;
    z-index: 2;
    min-height: 70px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 24px;
    background: rgba(0, 0, 0, 0.559);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 1px );
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.nav-menu img {
    height: 70px;
}

.hamburger{
    display: none;
    cursor: pointer;
}

.hamburger-bar{
    display: block;
    width: 4rem;
    height: 3px;
    margin: 1em;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    background-color: antiquewhite;
}

/* Styling for NavLink items */
.navbar a {
    text-decoration: none;
    color: #333; 
    padding: 10px 10px;
    transition: color 0.3s ease;
}

.navbar a:hover {
    color: #007BFF; 
}


.logo {
    font-size: 1.2em; /* Adjust size as necessary */
    font-weight: bold;
}

@media (max-width:990px){
    .navbar{
        transition: 0.5s; 
    }


    .hamburger{
        display: block;
        position: fixed;
    }

    .hamburger.active .hamburger-bar:nth-child(2){
        opacity: 0;
    }
    .hamburger.active .hamburger-bar:nth-child(1){
        transform: translateY(19px) rotate(225deg)
    }
    .hamburger.active .hamburger-bar:nth-child(3){
        transform: translateY(-19px) rotate(-225deg)
    }
    .nav-menu{
        position: fixed;
        left: -100%;
        top: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgba(5, 112, 109, 0.98);
        width: 100%;
        height: calc(100vh - 70px);
        text-align: center;
        transition: 0.5s;
        overflow: scroll;
    }

    .nav-menu.active{
        left:0;  
        background-color: #104e58f9;   
    }

    
}