/* src/ContactForm.css */
.contact-form-flex-container {
  margin-bottom: 40px;
  border: 5px solid rgb(0, 62, 162);
  border-radius: 10px;
  display: flex;
  background: #f9f9f9;
  /* padding: 2rem; */
  max-width: 95vw;
  flex-direction: row;
}

.booking-contact{
  text-align: center;
  padding: 1rem;
}

.booking-contact h3 {
  font-size: 2rem;
}

.booking-contact p {
  font-size: 1.5rem;
}

.contact-form {
    width: calc(50rem - 10vw);
    max-width: 95vw;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

.form-group {
  position: relative;
  margin-bottom: 2rem;
}
  
input,
textarea {
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  background: #fff;
}

input:focus,
textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}

/* label {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  font-size: 1rem;
  color: #666;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s;
  background: #f9f9f9;
  padding: 0 0.25rem;
  margin-left: -0.25rem;
} */

input:not(:placeholder-shown) + label,
input:focus + label,
textarea:not(:placeholder-shown) + label,
textarea:focus + label {
  top: -0.75rem;
  left: 0.75rem;
  font-size: 0.85rem;
  color: #007bff;
}

.error-message {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.5rem;
}

button {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
}

@media (max-width:990px){
  .contact-form-flex-container {
    
    flex-direction: column-reverse;
  }
}